import ResumesForAustralia from '@/components/resumes-for-australia-components/resumes-for-australia'
import FullWidthLayout from '@/layouts/full-width-layout'
import { makeStaticProps } from '@/services/i18n'
import languageDetector from '@/services/language-detector'
import { useRouter } from 'next/router'

/*
  This page was written under the guidance of
  https://www.broadplace.com/blog/seo-for-saas-the-anatomy-of-the-perfect-saas-landing-page-with-templates/
  using the 'persona' approach.
*/
const Index = () => {
  const router = useRouter()

  const detectedLocale = languageDetector.detect()
  if (detectedLocale !== 'en') {
    void router.replace(`/${detectedLocale}/`)
  }

  return <ResumesForAustralia />
}

Index.layout = FullWidthLayout

export default Index

const getStaticProps = makeStaticProps([
  'resumes-for-australia',
  'index',
  'upgrade',
  'header',
  'footer'
])
export { getStaticProps }
